<ng-template [ngIf]="withoutRootComponents">
  <router-outlet></router-outlet>
</ng-template>

<ng-template [ngIf]="!withoutRootComponents">
  <div class="body-container">
    <div class="header row">
      <div class="navbar-brand col-md-2 col-sm-3 col-3">
        <a class="pe-5 ms-4  " href="/mypage"> <img
            src="./././assets/img/cartagena_logo.png" width="174" height="34" alt="Cartagena"></a>
      </div>
      <div class="col-md-10 col-sm-9 col-9 text-end"> 
        <ctg-my-nav></ctg-my-nav>
      </div>
    </div>
    <div class="body">
      <router-outlet></router-outlet>
    </div>
    <div class="footer">
      <ctg-footer></ctg-footer>
    </div>
  </div>
</ng-template>