import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BaseModel, OptionSetItem } from '@app/xModels/BaseModel';
import { IPicklistCategories } from '@app/xModels/CrmModel';
import { Education } from '@app/xModels/EducationInfo';
import { IMembership, Membership } from '@app/xModels/Membership';
import { RegistrationConsent, RegistrationMembership, RegistrationUser, RegistrationWorkplace } from '@app/xModels/RegistrationUser';
import { UserContacts } from '@app/xModels/UserContacts';
import { UserStatusModel } from '@app/xModels/UserStatusModel';
import { Workplace } from '@app/xModels/WorkingInfo';
import { StoreManageService } from '@app/xServices/StoreManageService.service';
import { CrmService } from '@app/xServices/crm.service';
import { DecryptPipe } from '@app/xServices/enctypt-decrypt.pipe';
import { TokenService } from '@app/xServices/token.service';
import { UserService } from '@app/xServices/user.service';
import { VippService } from '@app/xServices/vipp.service';
import { environment } from '@environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'ctg-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {

  public newsSubscription: boolean = true;
  private personalId: string = '';
  private personalDataBID: UserStatusModel = new UserStatusModel();
  public newUserForm?: UntypedFormGroup;
  public MembershipList: Membership[] = [];
  public displayMessageStatus: string = '';
  // public MembershipCurrentList: IPicklistCategories[] = [];
  //countryPicklist: IPicklistCategories[] = [];
  businessSectorPicklist: IPicklistCategories[] = [];
  public workplaceTypePicklist: IPicklistCategories[] = [];

  public disableSubmitBtn: boolean = false;
  public existUser: boolean = true;
  public statusType: boolean = false;
  public isMandatorySpecificName: boolean = false;
  private NewUser: RegistrationUser = new RegistrationUser();
  membershipService: any;

  workplaceType: IPicklistCategories[] = [
    { label: "Arbeidsgiver", value: 0 },
    { label: "Studiested", value: 1 },
  ]

  private sub: any;
  public mode: string='';
  public content: any = null;
  public invoiceOption: OptionSetItem[] = [];
  
  constructor(
    @Inject(DOCUMENT) private document: Document, 
    public fb: UntypedFormBuilder, 
    private userService: UserService,
    private crmService: CrmService,
    private tokenService: TokenService,

    private decryptPipe: DecryptPipe,

    private spinner: NgxSpinnerService,
    private router: Router,
    private r: ActivatedRoute,
    private storeManageService: StoreManageService,
    private vippService: VippService
  ) {
      this.sub = this.r.queryParams.subscribe(params => {
        this.mode = params['mode'] ?? 'bank';
      });

      try {
        let retrievedObjectBID = localStorage.getItem("adamBID");
        let decryptedDataBID = this.decryptPipe.transform(retrievedObjectBID!);
        this.personalDataBID = JSON.parse(decryptedDataBID!);
        this.personalId = this.personalDataBID.personID;
      } catch (error) {
        console.error("Not allowed to get BID data");
      }
      this.userService.getInvoiceOption().subscribe(res =>
        {
          this.invoiceOption = res;
        });
      this.GetMembershipList();
  }

  ngOnInit(): void {
    console.log('MODE:' + this.mode);
    this.spinner.show();
    this.newUserForm = this.fb.group({
      firstName: ['', [Validators.required, Validators.pattern(/^[^<>*!?,%()="'`{|}~;/$^]{2,35}$/)]], //  start with letter[length from 2 to 35 characters]],\\
      lastName: ['', [Validators.required, Validators.pattern(/^[^<>*!?,%()="'`{|}~;/$^]+$/)]], //  start with letter],
      userEmail: ['', [Validators.required, Validators.pattern(/^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i)]],
      employer: ['', [Validators.required, Validators.pattern(/^[^<>*!?,%()="'`{|}~;/$^]{2,50}$/)]], //  start with letter[length from 2 to 50 characters]],\\
      membershipType: [''],
      privacy: [false, [Validators.requiredTrue]],
      agreement: [false, [Validators.requiredTrue]],
      employerType: [''],
      sendFakturaVia: [961700002, Validators.required],
      consentChoiceEmail: [true],
      consentChoiceSMS: [true],
    });

    if (this.mode != 'member') {
      this.newUserForm = this.fb.group({
        ...this.newUserForm.controls,
        userPhone: ['', [this.mode == 'member' ? null : Validators.required, Validators.pattern(/^[^a-zA-Z<>*!?,%{}@+="'`~;:\/#/|$&^]+$/)]], //  @#$%^&_+=-"'|\?/.~`
        DNumber: ['', [this.mode == 'member' ? null :Validators.required, Validators.pattern(/^\s?\S+[a-zA-Z0-9_-\s+]{8,16}$/)]], //  length from 8 to 16 characters],
        //newsletter: ['', this.mode == 'member' ? null : Validators.required],
     });
    }
    this.workplaceTypePicklist = [...this.workplaceType];
    this.IsInputNotEmpty();
    this.spinner.hide();

    this.newUserForm.controls["membershipType"]
      .valueChanges.subscribe((value) => {
        console.log("membershipType changed is =" + value)
        this.membershipOnChange(value);
      });
  }

  private IsInputNotEmpty() {
    if (this.personalDataBID.givenName !== null || this.personalDataBID.givenName !== undefined) {
      this.newUserForm?.controls['firstName']?.setValue(this.personalDataBID.givenName, { onlySelf: true });
      this.newUserForm?.controls['lastName']?.setValue(this.personalDataBID.familyName, { onlySelf: true });
    }

  }


  private membershipOnChange(value: string) {

    this.MembershipList;
    let _memberItem = this.MembershipList.find(t => t.acan_vareid === value);

    if (_memberItem?.ctg_mandatoryworkplace) {
      this.isMandatorySpecificName = true;
      this.newUserForm?.get('employer')?.setValidators(Validators.required);
      this.newUserForm?.get('employerType')?.setValidators(Validators.required);
      this.newUserForm?.get('employerType')?.updateValueAndValidity();
      this.newUserForm?.get('employer')?.updateValueAndValidity();
    } 
    else {
      this.isMandatorySpecificName = false;
      this.newUserForm?.get('employer')?.setValidators(Validators.nullValidator);
      this.newUserForm?.get('employerType')?.setValidators(Validators.nullValidator);
      this.newUserForm?.get('employerType')?.updateValueAndValidity();
      this.newUserForm?.get('employer')?.updateValueAndValidity();
    }
    this.newUserForm?.get('employer')?.setValidators(Validators.nullValidator);
    this.newUserForm?.get('employerType')?.setValidators(Validators.nullValidator);
    this.newUserForm?.get('employerType')?.updateValueAndValidity();
    this.newUserForm?.get('employer')?.updateValueAndValidity();

    this.newUserForm?.markAllAsTouched();
  }

  public onSubmit() {

    this.spinner.show();
    this.disableSubmitBtn = true;
    this.NewUser.firstname = this.newUserForm?.value.firstName;
    this.NewUser.lastname = this.newUserForm?.value.lastName;
    this.NewUser.emailaddress1 = this.newUserForm?.value.userEmail;
    this.NewUser.acan_nyhetsbrev = this.newUserForm?.value.newsletter;
    this.NewUser.ctg_synchronizetripletex = false;
    this.NewUser.ctg_personid = this.personalId; // Text
    this.NewUser.acan_fodselsnummer = this.newUserForm?.value.DNumber?.toString() ?? ""; // Text
    this.NewUser.sendInvoiceOption = this.newUserForm?.value.sendFakturaVia;
    if (this.newUserForm?.value.membershipType !== null && this.newUserForm?.value.membershipType.trim() !== "") {
      this.NewUser.membershipRegistration = {
        membershipId: this.newUserForm?.value.membershipType,
        fromDate: new Date().toISOString(),
        invoicestartdate: new Date().toISOString(),
      } as RegistrationMembership;

      if (this.isMandatorySpecificName) {
        if (this.newUserForm?.value.employerType == 0) {
          this.NewUser.workplace = {
            ctg_name: this.newUserForm?.value.employer,
          } as Workplace;
        }
        else {
          this.NewUser.education = {
            ctg_name: this.newUserForm?.value.employer,
          } as Education;
        }
      }

      this.NewUser.consentList = [];
      let memberTerms = new RegistrationConsent();
      memberTerms.ctg_typeofconsentid  = environment.membershipCondition;
      memberTerms.ctg_givenconsent = this.newUserForm?.value.agreement;
      this.NewUser.consentList[0] = (memberTerms);

      let privacyTerms = new RegistrationConsent();
      privacyTerms.ctg_typeofconsentid = environment.personalDataCondition;
      privacyTerms.ctg_givenconsent = this.newUserForm?.value.privacy;
      this.NewUser.consentList!.push(privacyTerms);

      //consentChoiceSMS
      let consentSMS = new RegistrationConsent();
      consentSMS.ctg_typeofconsentid = environment.consentSMS;
      consentSMS.ctg_givenconsent = this.newUserForm?.value.consentChoiceSMS;
      this.NewUser.consentList!.push(consentSMS);

      //consentChoiceEmail
      let consentEmail = new RegistrationConsent();
      consentEmail.ctg_typeofconsentid = environment.consentEmail;
      consentEmail.ctg_givenconsent = this.newUserForm?.value.consentChoiceEmail;
      this.NewUser.consentList!.push(consentEmail);
      console.log("new user= " + JSON.stringify(this.NewUser));
    }
    
    if (this.mode == 'member') {
      this.NewUser.isRequireEmailVerify = true
    }
    else {
      this.NewUser.mobilephone = "0047" + this.newUserForm?.value.userPhone;
    }

    console.log("New user registration data: "+ JSON.stringify(this.NewUser))
    this.userService.Registration(this.NewUser)
    .subscribe({
      next: (data) => {
        if (data.responseData) {
          if (data.responseData && data.responseData.accessToken) {
            this.tokenService.ResetContactStorage();
            this.statusType = true;
            this.tokenService.SetToken(data.responseData.accessToken);

            const contactId = this.tokenService.GetIdentity().ContactId;
            this.storeManageService.AddContactId(contactId);

            this.spinner.hide();
            // Is Vipp Payment
            if (this.NewUser.sendInvoiceOption == 961700004) {
              this.vippRecurringProcess(contactId, data.responseData.membershipId, false);
            }
            else {
              this.router.navigate(["/inbox"], { relativeTo: this.r });
            }
          }
          else {
            this.VerifyRegistrationOnEmail(data.responseData.contactId, this.NewUser.sendInvoiceOption == 961700004, data.responseData.membershipId);
          }
          this.disableSubmitBtn = false;
        }
        else {
          this.spinner.hide();
          this.statusType = true;
          if (data.description == "BANK_MISSING_PERSONID") {
            this.displayMessageStatus = "Register with bank id require person information (personid). Please contact with administrator.";
          }
          else if (data.description == "MEMBER_EMAIL_DUPLICATE") {
            this.displayMessageStatus = "This email had been registered. If you forget your password, Please follow this <a href='/resetpassword/email/'>Reset password</a>";
          }
          this.disableSubmitBtn = false;
        }
      },
      error: (err) => {
        this.spinner.hide();
        console.error("errorMessage: " + err);
        //this.router.navigate(["/registration/result/" + err]);
        this.statusType = true;
        this.displayMessageStatus = "There have an error occurred. Please contact administrator.";
        this.disableSubmitBtn = false;
      }
    });

  }
    
  private VerifyRegistrationOnEmail(contactId: string, isVippPayment: boolean, memshiptypeId: string) {
    if (isVippPayment) {
      // Require verify from VIPP first
      this.vippRecurringProcess(contactId, memshiptypeId, true);
    }
    else {
      // Send an email to confirm
      this.userService.RequireWelcomeVerifyEmail(contactId).subscribe({
        next: (data) => {
          console.log(data);
          this.spinner.hide();
          this.router.navigate(["/registration/result/verifyemail"]);
        },
        error: (err) => {
          this.spinner.hide();
          console.error("errorMessage: " + err);
        }
      });
    }
  }

  private GetMembershipList() {
    this.userService.GetMembershipList("showonsignup").subscribe({
      next: (value: BaseModel<IMembership[]>) => {
        this.newUserForm?.get('membershipType')?.enable();
        this.MembershipList = value.responseData;
      },
      error: (err) => {
        this.newUserForm?.get('membershipType')?.disable();
        console.error("errorMessage: " + err);
      }
    });
  }

  public Redirect() {
    this.router.navigate(["/login"], { relativeTo: this.r });
  }

  public vippRecurringProcess(contactId: string, membershipId: string, isRequireEmailVerify: boolean) {
    this.vippService.registerToVippAgreement(contactId, membershipId, isRequireEmailVerify).subscribe(res => {
      this.spinner.hide();
      if (res != '') {
        this.document.location.href = res;
      }
    });;
  }
}



