import { Component } from '@angular/core';
import { UserService } from '@app/xServices/user.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { UntypedFormBuilder,  UntypedFormGroup } from '@angular/forms';

import { Education } from '@app/xModels/EducationInfo';
import { BaseModel } from '@app/xModels/BaseModel';

import { StoreManageService } from '@app/xServices/StoreManageService.service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { EducationService } from '@app/xServices/education.service';
import { MemberGroupService } from '@app/xServices/membergroup.service';
import { MemberGroupInfomation, MemberOnGroup, MyMemberGroup } from '@app/xModels/MemberGroupModel';
import { WorkplaceService } from '@app/xServices/workplace.service';

@Component({
  selector: 'app-user-mygroup',
  templateUrl: './user-mygroup.component.html',
  styleUrls: ['./user-mygroup.component.scss']
})
export class UserMyGroupComponent {

  public userId: string = "";
  public myGroup: MyMemberGroup[] = [];

  pageLimit: number = 10;
  sortColumn: string = 'memberContactName';

  constructor(private userService: UserService,  
    private spinner: NgxSpinnerService, 
    private router: Router,
    public fb: UntypedFormBuilder,
    private storeManageService:StoreManageService,
    private memberGroupService: MemberGroupService,
    private wokplaceService: WorkplaceService
  ) {
    this.spinner.show();
    this.userId = this.storeManageService.GetContactId();
  }

  ngOnInit(): void {   
    this.Initialization(); 
  }

  private Initialization() {
    this.memberGroupService.myMemberGroup().subscribe({
      next: (data: BaseModel<Array<MemberGroupInfomation>> ) => {
        if (data && data.responseData) {
          data.responseData.forEach(a => {
            let obj: MyMemberGroup ={
              groupInfomation: a,
              currentPage: 1,
              members: [],
              totalItems: 0
            }
            this.getMemberOnGroup(a.memberGroupId, 1)
            this.myGroup.push(obj);
          });
        }            
      },
      error: (err) => {
        console.error("errorMessage: " + err);
        this.spinner.hide();
      },
    });
  }

  private async getMemberOnGroup(groupid: string, page: number) {
    this.wokplaceService.memberGroupUsers(groupid, true, null, page, this.pageLimit, this.sortColumn, "", null).subscribe(o => {
      if (o.responseData && o.responseData.length > 0) {
        let idx = this.myGroup.findIndex(a => a.groupInfomation?.memberGroupId == groupid);
        if (idx >= 0) {
          this.myGroup[idx].members = o.responseData;
          this.myGroup[idx].currentPage = page;
          this.myGroup[idx].totalItems = o.total ?? 0;
        }
      }
    });
  }

  pageChanged(event: any, obj: MyMemberGroup): void {
    console.log('Page changed to: ' + event.page);
    this.getMemberOnGroup(obj.groupInfomation?.memberGroupId!, event.page);
  }

  renderAddress(item: MyMemberGroup): string {
    if (!item.groupInfomation) return '';

    let d: string[] = [];
    if (item.groupInfomation.street) { d.push(item.groupInfomation.street); }
    if (item.groupInfomation.street2) { d.push(item.groupInfomation.street2); }
    if (item.groupInfomation.city) { d.push(item.groupInfomation.city); }
    if (item.groupInfomation.postalCode) { d.push(item.groupInfomation.postalCode); }

    if (d.length == 0) return '';

    return d.join(', ');
  }
}
