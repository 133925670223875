<div class="row" *ngIf="!isDisplayEditDialog">
    <div class="col-12 text-right">
        <button class=" btn btn-primary text-nowrap f-2 fw-bold btn-wrapper-info" [disabled]="editModeEducation"
            id="btnNewEducation" (click)="newEducation()">Ny utdannelse</button>
    </div>
</div>

<div class="row" *ngIf="isDisplayEditDialog && !editModeEducation">
    <div class="col-12 col-md-8 mb-4">
        <form [formGroup]="createNewEducationForm" class="notecard">
            <div class="col-12">
                <label for="nInputJobb" class="form-label small">Navn</label>
                <span class="text-danger">*</span>
                <input type="text" class="form-control" formControlName="employer" id="nInputJobb" placeholder="" maxlength="50">
                <input type="hidden" class="form-control" formControlName="educationId">
            </div>
            <div class="col-12 col-md-10 col-lg-6">
                <label for="nInputYrke" class="form-label small">Fra</label><span class="text-danger">&nbsp;*</span>
                <div class="input-group">
                    <input
                        class="form-control"
                        placeholder="yyyy-mm-dd"
                        name="dpEducationStartDate"
                        formControlName="educationStartDate"
                        ngbDatepicker
                        #d="ngbDatepicker"
                    />
                    <button class="btn btn-outline-secondary bi bi-calendar3" (click)="d.toggle()" type="button"></button>
                </div>
            </div>
            <div class="col-12 col-md-10 col-lg-6">
                <label for="nInputYrke" class="form-label small">Til</label>
                <div class="input-group">
                    <!--[(ngModel)]="model"-->
                        <input
                            class="form-control"
                            placeholder="yyyy-mm-dd"
                            name="dpEducationEndDate"
                            formControlName="educationEndDate"
                            ngbDatepicker
                            #e="ngbDatepicker"
                        />
                        <button class="btn btn-outline-secondary bi bi-calendar3" (click)="e.toggle()" type="button"></button>
                    </div>
            </div>
            <div class="mt-3 d-block">
                <span *ngIf="!isValidSubmit" class="warning-box">Fyll ut informasjon for å kreve feltet</span>
                <button class=" btn btn-primary text-nowrap f-2 fw-bold btn-wrapper-info" [disabled]="btnSaveEducationDisable"
                    id="btnSaveEducation" (click)="onSaveDataEducation()">Lagre</button>
                <button class=" btn text-nowrap  f-2 fw-bold "
                    (click)="isDisplayEditDialog=!isDisplayEditDialog">Avbryt</button>
            </div>
          </form>
    </div>
  </div>

<div class="row"  *ngIf="!isDisplayEditDialog">
    <div class="col-12 mt-1">
        <div class="content-container">
            <div *ngIf="this.listEducations?.length == 0">
                <div class="text-center">Ingen tidligere utdanninger registrert</div>
            </div>

            <div *ngIf="this.listEducations?.length != 0">
                <div class="row d-none d-md-flex mb-2 mt-3">
                    <div class="col-3">
                    </div>
                    <div class="col-3">
                      Fra:<br>
                    </div>
                    <div class="col-3">
                      Til:<br>
                    </div>
                  </div>
                <ng-container *ngFor="let education of listEducations">
                    <div class="row mb-5 mb-md-2 spacing-row" [ngClass]="{'row-warning': education.isEdited === true}">
                        <div class="col-12 col-md-3">
                          <p class="fs-5 fw-normal">{{education.ctg_name}}</p>
                        </div>
                        <div class="col-12 col-md-3">
                            <p class="fs-5">{{education.ctg_datefrom | date:'dd.M.yy'}}</p>
                        </div>
                        <div class="col-12 col-md-3">
                            <p class="fs-5">{{education.ctg_dateto | date:'dd.M.yy'}}</p>
                        </div>
                        <div class="col-12 col-md-3 text-right">
                            <div class="item-tools">
                                <div *ngIf="!editModeEducation">
                                    <div class="icon-flex">
                                        <div class="icon-wrapper btn-wrapper-info" (click)="editEducation(education.ctg_educationid)">
                                        <i class="bi bi-pencil-fill"></i>
                                        </div>
                                    </div>
                                    <div class="icon-flex">
                                        <div class="icon-wrapper btn-wrapper-delete" (click)="removeEducation(education.ctg_educationid);">
                                        <i class="bi bi-x"></i>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="editModeEducation && education.isEdited">
                                    <div class="icon-flex">
                                        <button class=" btn text-nowrap f-2 fw-bold btn-wrapper-delete" (click)="confirmRemoveEducation(education.ctg_educationid)">Bekreft Slett</button>
                                    </div>
                                    <div class="icon-flex">
                                        <div class="icon-wrapper btn-wrapper-tranparent" (click)="cancelRemoveEducation(education.ctg_educationid);">
                                            <i class="bi bi-x"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>  
    </div>
</div>