import { Component, OnInit, ElementRef } from '@angular/core';
import { MembershipService } from '@app/xServices/membership.service';
import { environment } from '@environments/environment';
import { DecryptPipe } from '@app/xServices/enctypt-decrypt.pipe';
import { ActivatedRoute, Router } from '@angular/router';
import { TokenService } from '@app/xServices/token.service';
import { WorkplaceService } from '@app/xServices/workplace.service';
import { MemberGroupMenu } from '@app/xModels/MemberGroupModel';
import { StoreManageService } from '@app/xServices/StoreManageService.service';
import { HelperService } from '@app/xServices/help.service';
import { Subject, takeUntil } from 'rxjs';
import { UserService } from '@app/xServices/user.service';
import { UserContacts } from '@app/xModels/UserContacts';
import { BaseModel } from '@app/xModels/BaseModel';

@Component({
  selector: 'ctg-my-nav',
  templateUrl: './my-nav.component.html',
  styleUrls: ['./my-nav.component.scss']
})
export class MyNavComponent implements OnInit {
  menuValue:boolean=false;
  menu_icon :string ='bi bi-list';
  memberGroupMenu: Array<MemberGroupMenu> = [];
  
  public isAuthorized: boolean = false;
  public MyUserName: string = "";
  public inboxCount: number = 0;

  constructor(private activatedRoute: ActivatedRoute, 
    private tokenService: TokenService, 
    private decryptPipe: DecryptPipe, 
    private router: Router,
    private membershipService: MembershipService,
    private workplaceService: WorkplaceService,
    private storeManageService: StoreManageService,
    private helperService: HelperService,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    this.SendLogInStatus();
    this.GetInboxCount();
    this.GetGroupMemberOwner();
  }

  private SendLogInStatus() {
    this.tokenService.SendLogInStatusEvent
      .subscribe((data: boolean) => {
        this.isAuthorized = data;
        this.GetInboxCount();

        //var con = this.tokenService.GetIdentity();
        //console.log(con);
        //this.GetGroupMemberOwner();
        /*if (data) {
          this.isAuthorized = this.tokenService.IsAuthorized();
        }*/
       this.GetUserInfo();
      });

    this.isAuthorized = this.tokenService.IsAuthorized();
  }

  public inLogOut() {
    this.isAuthorized = false;
    this.tokenService.ClearlocalStorage();
    localStorage.removeItem("adamCGUID");
    this.router.navigate(["/home"]);
  }
  
  private GetGroupMemberOwner() {
    let userDataStore = this.storeManageService.GetUserData();
    if (!this.helperService.isEmptyOrSpaces(userDataStore.contactid)) {
      this.memberGroupMenu = userDataStore.membergroupMenus!;
    }
  }

  private GetUserInfo() {
    var con = this.tokenService.GetIdentity();
    this.userService.GetUserInfo(con.ContactId).subscribe({
      next: (value: BaseModel<UserContacts>) => {
        this.tokenService.ResetContactStorage();
        this.storeManageService.AddContactId(con.ContactId);
        this.storeManageService.AddNewUserInfoState(value.responseData);
        this.storeManageService.ResetState('contactId'); 

        //this.tokenService.SetToken(value.responseData.accessToken);

        this.memberGroupMenu = value.responseData.membergroupMenus ?? [];
      },
      error: (err) => {
        console.error("errorMessage: " + err);
      }
    });
  }

  private async GetInboxCount() {
    this.membershipService.GetInboxCount().subscribe({
      next: (data) => {
        this.inboxCount = data;       
      }
    });
  }

  public openMenu(){
    this.menuValue =! this.menuValue ;
    this.menu_icon = this.menuValue ? 'bi bi-x' : 'bi bi-list';
  }

  public closeMenu() {
    this.menuValue = false;
    this.menu_icon = 'bi bi-list';
  }

  public rederMenuText(menu: MemberGroupMenu): string {
    return menu.memberGroupName + (menu.childGroup && menu.childGroup.length ? " &rtrif;" :"");
  }
}
