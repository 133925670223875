
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseModel } from '@app/xModels/BaseModel';
import { UserContacts } from '@app/xModels/UserContacts';
import { HelperService } from '@app/xServices/help.service';
import { StoreManageService } from '@app/xServices/StoreManageService.service';
import { TokenService } from '@app/xServices/token.service';
import { UserService } from '@app/xServices/user.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'ctg-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public loginForm!: UntypedFormGroup;
  
  displayStatus: boolean = false;
  displayMessageStatus: string = '';

  constructor(public fb: UntypedFormBuilder, 
    private tokenService: TokenService,
    private spinner: NgxSpinnerService,
    private storeManageService: StoreManageService,
    private router: Router,
    private r: ActivatedRoute,
    private userService: UserService,
    private helperService: HelperService,
  ) {   
      //this.tokenService.ClearlocalStorage();
  }
  ngOnInit(): void {
    let userId = this.storeManageService.GetContactId(); 
    // If token not expire => My page
    if (!this.helperService.isEmptyOrSpaces(userId)) {
      this.router.navigate(["/mypage"]);
    }

    this.loginForm = this.fb.group({
      username: ['', [Validators.required, Validators.email]], 
      password: ['', [Validators.required]], 
    });
  }

  onSubmit() {
    this.displayStatus = false;
    console.log(this.loginForm);
    if (!this.loginForm?.valid) {
      return;
    }

    this.spinner.show();
    this.tokenService.UserLogin(this.loginForm?.value.username, this.loginForm?.value.password).subscribe(
    {
      next: (data) => {
        if (data.responseData && data.responseData.accessToken) {
          this.tokenService.ResetContactStorage();
          this.tokenService.SetToken(data.responseData.accessToken);
          this.router.navigate(["/inbox"], { relativeTo: this.r });
        }
        else {
          this.displayStatus = true;
          if (data.description == 'INVALID_EMAIL') {
            this.displayMessageStatus = 'Vi fant ikke denne e-posten på systemet';
          }
          else if (data.description == 'INVALID_PASSWORD') {
            this.displayMessageStatus = 'feil passord';
          }
          else if (data.description == 'INVALID_MORETHANONE_ACCOUNT') {
            this.displayMessageStatus = 'Vi fant ut at det har duplisert denne e-posten. Ta kontakt med administrator';
          }
          else {
            this.displayMessageStatus = 'Det har oppstått en feil. Vennligst kontakt administrator';
          }
        }
        this.spinner.hide();
      },
      error: (err) => {
        this.spinner.hide();
        this.displayStatus = true;
        this.displayMessageStatus = "Det har oppstått en feil. Vennligst kontakt administrator";
      },
    });
  }
}
