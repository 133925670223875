import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common'
import { CourseInfo, CourseResponse } from '@app/xModels/CourseModel';
import { IPicklistCategories } from '@app/xModels/CrmModel';
import { IFileGetApiModel, FileGetApiModel } from '@app/xModels/FileGetApiModel';
import { IKategoriMedlemsFoldId, IMembershipBenefit, IMembershipBenefitCategory } from '@app/xModels/UserMembershipBenefits';
import { IUserNews } from '@app/xModels/UserNews';
import { CourseService } from '@app/xServices/course.service';
import { CrmService } from '@app/xServices/crm.service';
import { DecryptPipe } from '@app/xServices/enctypt-decrypt.pipe';
import { NgxSpinnerService } from 'ngx-spinner';
import { BaseModel, OptionSetItem, OptionSetString } from '@app/xModels/BaseModel';
import swal from 'sweetalert2';
import { WorkplaceService } from '@app/xServices/workplace.service';
import { DialogResponseSendEmail, MemberGroupAdminStat, MemberGroupInfomation, MemberOnGroup } from '@app/xModels/MemberGroupModel';
import { FormArray, FormBuilder, FormGroup, UntypedFormGroup } from '@angular/forms';
import { TokenService } from '@app/xServices/token.service';

@Component({
  selector: 'app-user-membergroup',
  templateUrl: './user-membergroup.component.html',
  styleUrls: ['./user-membergroup.component.scss']
})


export class UserMemberGroupComponent implements OnInit {
  private sub: any;
  parameter: string = "";
  id: string = ""; 

  public memberList: Array<MemberOnGroup> = [];
  public selectedList: Array<MemberOnGroup> = [];
  public groupInfo?: MemberGroupInfomation;
  public groupStat?: MemberGroupAdminStat;

  currentPage: number = 1;
  itemsPerPage: number = 25;//25;
  totalItems: number = 25;//25;
  disabled: boolean = false;
  loading: boolean = true; 
  sortColumn: string = 'memberContactName';
  sortDirection: 'asc' | 'desc' = 'asc';
  configTableMaxRow: number = 25;
  filterStatus: string = "all";
  filterText: string = "";

  public isSelectedALl: boolean = false;
  constructor(private service: WorkplaceService, 
    private tokenService: TokenService,
    private sanitizer: DomSanitizer, 
    private spinner: NgxSpinnerService,
    private router : Router,
    private route: ActivatedRoute, 
    private datepipe: DatePipe,
    private fb: FormBuilder
  ) {
      this.sub = this.route.params.subscribe(params => {
        this.id = params['id']; 
        this.loadData();

        this.service.memberGroupInfo(this.id).subscribe(o => {
          if (o && o.responseData) {
            this.groupInfo = o.responseData;

            
          }
        });

        this.service.memberGroupStat(this.id).subscribe(a => {
          if (a && a.responseData) {
            this.groupStat = a.responseData;
          }
        });

      });
  }

  ngOnInit(): void { 
    
  }
  filterChange() {
    this.loadData();
  }

  loadData(page: number = 1) {
    this.spinner.show();
    let status = this.filterStatus == "all" ? null : this.filterStatus;

    this.service.memberGroupUsers(this.id, false, status, page, this.configTableMaxRow, this.sortColumn, this.sortDirection, this.filterText).subscribe(o => {
      if (o) {
        if (o.responseData && o.responseData.length > 0) {
          this.memberList = o.responseData;
          this.totalItems = o.total ?? 0;
          this.currentPage = page;

          this.memberList.forEach(a => {
            if (this.selectedList.length > 0 && this.selectedList.findIndex(x => x.memberContactId == a.memberContactId) > -1) {
              a.isSelected = true;
            }
          });
          //Manage checkall control
          this.manageCheckAllControl();
        } 
        else if (o.statusCode != '200' || o.description != '') {
          this.memberList = [];
          this.totalItems = 0;
          this.currentPage = 0;
        } 
      }

      this.spinner.hide();
    });
  }

  pageChanged(event: any): void {
    console.log('Page changed to: ' + event.page);
    this.loadData(event.page);
}

sort(column: string) {
  console.log('sorted');
  if (this.sortColumn === column) {
    this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
  } else {
    this.sortColumn = column;
    this.sortDirection = 'asc';
  }
  this.currentPage = 1;
  this.loadData(this.currentPage);
  // this.sortProjects();
}

  changeCheckBox(e: any, member: MemberOnGroup) {
    if (e.currentTarget.checked) {
      if (this.selectedList.findIndex(a => a.memberContactId == member.memberContactId) == -1) {
        this.selectedList.push(member);
      }
    }
    else {
      this.selectedList = this.selectedList.filter(a => a.memberContactId != member.memberContactId);
    }

    // Manage Checkbox all
    this.manageCheckAllControl();
  }
  manageCheckAllControl() {
    if (this.memberList.filter(a => a.isSelected).length == this.memberList.length)
      {
        this.isSelectedALl = true;
      }
      else {
        this.isSelectedALl = false;
      }
  }

  selectAllContact(e: any) {
    if (e.currentTarget.checked) {
      this.memberList.forEach(a => a.isSelected = true);
      // Remove old one first > then add all
      this.selectedList = this.selectedList.filter(a => !this.memberList.map(b => b.memberContactId).includes(a.memberContactId));
      this.selectedList = this.selectedList.concat(this.memberList);
    }
    else {
      this.memberList.forEach(a => a.isSelected = false);
      this.selectedList = this.selectedList.filter(a => !this.memberList.map(b => b.memberContactId).includes(a.memberContactId));
    }
  }
  
  OnlyMemberOnChecked() {
    return this.selectedList.filter(a => a.isSelected);
  }

  submitEmail(response: DialogResponseSendEmail): void {
    this.spinner.show();
    const contactId = this.tokenService.GetIdentity().ContactId;
    this.service.SendEmailMemberGroup(contactId, response.recipientIds, response.accountRecipientIds, response.emailSubject, response.emailBody).subscribe(res =>{
      this.spinner.hide();
      if (res.responseData) {
        swal.fire({
          title: 'E-postmeldingen er sendt',
          icon: 'success',
          confirmButtonText: 'OK',
        }).then((result:any) => {
          this.memberList.forEach(a => a.isSelected = false);
          this.selectedList = [];
          this.isSelectedALl = false;
        });
      }
    });
  }

}
