import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseModel } from '@app/xModels/BaseModel';
import { ServerToken } from '@app/xModels/ServerToken';
import { UserContacts } from '@app/xModels/UserContacts';
import { UserStatusModel } from '@app/xModels/UserStatusModel';
import { StoreManageService } from '@app/xServices/StoreManageService.service';
import { BankidService } from '@app/xServices/bankid.service';
import { DecryptPipe, EncryptPipe } from '@app/xServices/enctypt-decrypt.pipe';
import { TokenService } from '@app/xServices/token.service';
import { UserService } from '@app/xServices/user.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'ctg-bank-id-redirect',
  templateUrl: './bank-id-redirect.component.html',
  styleUrls: ['./bank-id-redirect.component.scss']
})
export class BankIdRedirectComponent implements OnInit {

  public statusType: string = "warning";
  public noSuccessMessage: boolean = false;
  public successMessage: boolean = false;

  private operationCode: string = "";
  private stateTmp: UserStatusModel = new UserStatusModel();
  private newToken: ServerToken = new ServerToken();
  private userContactId: string = "";




  constructor(private bankidService: BankidService, private tokenService: TokenService, private spinner: NgxSpinnerService,
    private router: Router, private r: ActivatedRoute,
    private encryptPipe: EncryptPipe,
    private decryptPipe: DecryptPipe, private userService: UserService, private storeManageService: StoreManageService) {
      this.storeManageService.ResetState('contactId'); 

    //  this.StatusCheck();



  }

  ngOnInit(): void {

    /*------- Open the login window. --------------*/
    let _code = this.r.snapshot.queryParamMap.get('code');

    if (_code == null || _code == undefined) {
      let _error = this.r.snapshot.queryParamMap.get('error');
      if (_error == "access_denied")
        //TODO Create message when  token login canceled
        this.ErrorMessage();
    } else {
      /*--------------- Then we send this data to the backend for processing. -----------*/
      console.info("Sending data for authorization to BankID  :" + _code);
      this.operationCode = _code;
      this.PostLogin(_code);

    }

  }


  private PostLogin(code: string) {
    this.spinner.show();
    this.bankidService.GetBankIdInfo(code).subscribe({
      next: (res) => {
        this.stateTmp = res.responseData;
        console.log("Successfully received data from BankId.");
        this.tokenService.SetToken(res.responseData.token);

        // Set data  from BankId to cache
        this.BankIdDataToCache(res.responseData);

        // The entrance was via a link from the CPM.
        (this.IsLinkFromCrm()) ? this.router.navigateByUrl('/registration/update') : this.GetTokenFromServer(res.responseData.personID);
      },
      error: (er) => {
        console.error("errorMessage: " + JSON.stringify(er));
        this.spinner.hide();
        this.ErrorMessage();

      },
    });
  }



  public StatusCheck() {
    this.router.navigate(["/login"], { relativeTo: this.r });
  }



  /** 1)If we get a response, then it's a normal login. 
   * Then we redirect further to the main page.  And set the token.
   * 
   * 2) If the answer is 400/401, then this person is visiting the site for the first time. 
   * Then we save the data from the bankID to the cache as "adamBID". And redirect to registration.
   * */
  private GetTokenFromServer(personID: string) {
    this.tokenService.GetNewToken(personID).subscribe({
      next: (data) => {
        this.tokenService.SetToken(data.responseData.accessToken);
        this.tokenService.SetRefreshToken(data.responseData.refreshToken);
        this.router.navigate(["/inbox"], { relativeTo: this.r });
      },
      error: (err) => {
        this.router.navigateByUrl('/registration');
        this.spinner.hide();
      },
    });

  }

  private IsLinkFromCrm(): boolean {
    let result: boolean = false;
    /* -------------------------------------------------------------------------- */
    /*               Checking if the GUID was transferred from CRM.               */
    /* ----------- If this is the first login of an existing CRM user. ---------- */

    try {
      let retrievedObjectGUID = localStorage.getItem("adamCGUID"); //adamCGUID - CRM Guid
      let decryptedGUID = this.decryptPipe.transform(retrievedObjectGUID!);
      let _personalCGUID = JSON.parse(decryptedGUID!);

      if ((_personalCGUID !== "") && (_personalCGUID.length > 30) && (_personalCGUID !== undefined))
        result = true;

    } catch (error) { result = false; }
    return result;
  }




  private BankIdDataToCache(_stateTmp: UserStatusModel) {
    // Set data  from BankId to cache
    let stringData = JSON.stringify(_stateTmp);
    let encryptedData = this.encryptPipe.transform(stringData)
    localStorage.setItem("adamBID", encryptedData);
  }


  //TODO implement Error messages fir URL_Redirect
  private ErrorMessage() {
    this.statusType = "danger";
    this.successMessage = false;
    this.noSuccessMessage = true;
  }
  







}
