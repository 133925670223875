import { UserContacts } from "@app/xModels/UserContacts";

export class AddUserInfoAction {
    static readonly type = '[User ADD] Add';
    constructor(public payload: UserContacts) { }
}

export class RemoveUserInfoAction {
    static readonly type = '[User REMOVE] Remove';
    constructor(public payload: boolean) { }
}