
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CrmService } from './crm.service';
import { Observable } from 'rxjs';
import { BaseListResponse, BaseModel } from '@app/xModels/BaseModel';
import { MemberGroupInfomation, MemberGroupMenu, MemberOnGroup, MyMemberGroup } from '@app/xModels/MemberGroupModel';

@Injectable({
  providedIn: 'root'
})
export class MemberGroupService {

  constructor(private crmService: CrmService, private domSanitizer: DomSanitizer) { }

  public myMemberGroup(): Observable<BaseModel<Array<MemberGroupInfomation>>> {
    var endPoint = "MemberGroup/MyMemberGroup";
    return this.crmService.get<any>(endPoint);
  }

};

