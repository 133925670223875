import { MemberGroupMenu } from "./MemberGroupModel";

export class UserContacts {
  firstname :string="";
  lastname:string="";
  emailaddress1 :string="";
  emailaddress2 :string="";
  mobilephone :string="";
  telephone1 :string="";
  telephone2 :string="";
  address1_line1 :string="";
  address1_postofficebox :string="";
  address1_postalcode :string="";
  address1_city :string="";
  acan_land :string="";
  contactid:string="";
  address1_composite:string="";
  accessToken:string="";
  ctg_medlemi?:number;
  sendInvoiceVia?:number;
  membergroupMenus?: MemberGroupMenu[];
}