import { OptionSetItem } from "./BaseModel";
import { Education } from "./EducationInfo";
import { UserContacts } from "./UserContacts";
import { Workplace } from "./WorkingInfo";

export class RegistrationUser {
  address1_city!: string;   //city
  acan_land!: string; //Country
  firstname!: string;
  lastname!: string;
  mobilephone?: string;  //UserPhone
  address1_postalcode  !: string; //ZipCode
  address1_line1 !: string; //LocationOrStreetName
  emailaddress1 !: string; //Email
  acan_bransje!: string;
  acnd_arbeidsgiverfraweb !: string;
  acan_nyhetsbrev !: boolean;
  ctg_personid! : string;
  acan_fodselsnummer! : string;
  ctg_invitationvalidto!: string;
  ctg_synchronizetripletex : boolean | undefined;
  ctg_medlemi!: string; //ctg_medlemsi
  accessToken!:string;
  membershipRegistration?: RegistrationMembership;
  //workplaceList: RegistrationWorkplace[] | undefined = undefined;
  consentList : RegistrationConsent[] | undefined = undefined;
  education?: Education;
  workplace?: Workplace;
  isRequireEmailVerify?: boolean;
  sendInvoiceOption?: number;
}

export class UpdateUser{

  address1_city?: string;   //city
  acan_land?: string; //Country
  firstname?: string;
  lastname?: string;
  mobilephone ?: string;  //UserPhone
  address1_postalcode  ?: string; //ZipCode
  address1_line1 ?: string; //LocationOrStreetName
  emailaddress1 ?: string; //Email
  acan_bransje?: string;
  acnd_arbeidsgiverfraweb ?: string;
  acan_nyhetsbrev ?: boolean;
  ctg_personid? : string;
  acan_fodselsnummer? : string;
  ctg_invitationvalidto?: string;
  ctg_medlemi?:number | null;
  membershipRegistration : RegistrationMembership[] | undefined = undefined;
  workplaceList: RegistrationWorkplace[] | undefined = undefined;
  sendInvoiceVia?: number | null;
  consentList : RegistrationConsent[] | undefined = undefined;
  Merge(userContactInfo: UserContacts) {
    this.address1_city= userContactInfo.address1_city;   //city
    this.acan_land= userContactInfo.acan_land; //Country
    this.firstname= userContactInfo.firstname;
    this.lastname= userContactInfo.lastname;
    this.mobilephone= userContactInfo.mobilephone;  //UserPhone
    this.address1_postalcode = userContactInfo.address1_postalcode; //ZipCode
    this.address1_line1 = userContactInfo.address1_line1; //LocationOrStreetName
    this.emailaddress1 = userContactInfo.emailaddress1; //Email
    this.ctg_medlemi= userContactInfo.ctg_medlemi;
    this.sendInvoiceVia = userContactInfo.sendInvoiceVia;
  }
}

export class RegistrationMembership{
  fromDate  = new Date().toISOString();
  invoicestartdate =  new Date().toISOString();
  membershipId : string ="";
}

export class RegistrationWorkplace{
  ctg_contactid !: string;
  ctg_name !: string;
  //ctg_datefrom = new Date().toISOString();
  ctg_type !: number;// = 961700000;
}

export class RegistrationConsent{
  ctg_typeofconsentid: string = '';
  ctg_source : number = 961700000;//web 
  ctg_givenconsent : boolean = true;
  //ctg_dateofconsent = new Date().toISOString();
}

export class RegistrationResponse{
  accessToken: string = '';
  contactId: string = '';
  membershipId: string = '';
}

export class ResetPasswordRequest {
  currentPassword?: string;
  newPassword: string = '';
  requireVerifyCurrentPassword?: boolean;
  tokenCode?: string;
}

export class UpdateUserInfoResponse {
  redirectUrl?: string;
  isSuccess?: boolean;
  requireToRedirect?: boolean;
}
