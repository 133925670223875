import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { TokenService } from '@app/xServices/token.service';

import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {
  private IsAuthorized: boolean = false;

  constructor(
    private router: Router, private tokenService: TokenService ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {


    this.tokenService.SendLogInStatusEvent
      .subscribe((data: boolean) => {
        if (!data) {
          this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
          return false;
      } else {
        return true;
      }
      });

    /*this.IsAuthorized = this.tokenService.IsAuthorized();

    if (!this.IsAuthorized) {
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return false;
    } else {
      return true;
    }*/
  }
}
