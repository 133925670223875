<div class="row " *ngIf="myGroup != null && myGroup.length > 0">
    <div class="col-12 bg-primary-header ps-4 mt-3  text-start">
      <p class="fs-4 fw-normal pt-3">Medlemsgruppe</p>
    </div>
  </div>
  <div class="row ">
    <div class="col-12 ">
      <!-- Content -->
      <div class="col-md-12 pt-4 " *ngFor="let obj of myGroup; let i = index">
        <hr *ngIf="i > 0">
        <div class="row ps-3 flex-row-reverse">
            <!-- Main part -->
            <div class="title-container">
              <p class="fs-4">{{obj.groupInfomation?.memberGroupName ?? ''}}</p>
            </div>
        </div>

        <div class="row ps-3 flex-row-reverse">
            <div class="col-12">
                <p>
                    <i class="bi bi-house-fill me-2 bi--lg"></i>
                    {{ renderAddress(obj) }}
                </p>
            </div>
        </div>
        <div class="row ps-3 flex-row-reverse" *ngIf="obj.groupInfomation?.emailaddress">
            <div class="col-12">
                <p>
                    <i class="bi bi-envelope-fill me-2 bi--lg"></i>
                    {{ obj.groupInfomation?.emailaddress }}
                </p>
            </div>
        </div>
        <div class="row ps-3 flex-row-reverse" *ngIf="obj.groupInfomation?.parentGroupName">
            <div class="col-12">
                <p>
                    <i class="bi bi-luggage-fill me-2 bi--lg"></i>
                    {{ obj.groupInfomation?.parentGroupName }}
                </p>
            </div>
        </div>
        <div class="row ps-3 flex-row-reverse" *ngIf="obj.groupInfomation?.primaryContactName">
            <div class="col-12">
                <p>
                    <i class="bi bi-person-fill me-2 bi--lg"></i>
                    {{ obj.groupInfomation?.primaryContactName }}
                </p>
            </div>
        </div>
        <div class="row ps-3">
            <ng-container *ngIf="obj.members && obj.members.length > 0">
                <div class="table-responsive pt-4">
                    <table class="table align-items-center table-flush">
                      <thead class="thead-light">
                        <tr>
                          <th class="sort" data-sort="memberContactName" scope="col" >Navn</th>
                          <th class="sort" data-sort="" scope="col" >Verv</th>
                          <th class="sort" data-sort="memberContactEmail" scope="col" >Epost</th>
                        </tr>
                      </thead>
                      <tbody class="list" >
                        <tr *ngFor="let member of obj.members">
                          <td>{{ member.memberContactName }}</td>
                          <td>{{ member.memberPostName }}</td>
                          <td>{{ member.memberContactEmail }}</td>
                        </tr>
                      </tbody>
                    </table>
                </div>
                <div class="card-footer py-4">
                    <nav aria-label="...">
                        <pagination [totalItems]="obj.totalItems"
                            [itemsPerPage]="pageLimit"
                            [(ngModel)]="obj.currentPage"
                            (pageChanged)="pageChanged($event, obj)"
                            class="justify-content-end mb-0"
                            [maxSize]="5"
                            [boundaryLinks]="true"
                            [disabled]="false"
                            [customPreviousTemplate]="prevTemplate"
                            [customNextTemplate]="nextTemplate"
                            firstText="Først"
                            lastText="Sist">
                        </pagination>
                        <ng-template #nextTemplate let-currentPage="currentPage">
                            <ng-container>
                              <i class='bi bi-caret-right'></i>
                            </ng-container>
                          </ng-template>
              
                          <ng-template #prevTemplate let-currentPage="currentPage">
                            <ng-container>
                              <i class='bi bi-caret-left'></i>
                            </ng-container>
                          </ng-template>
                      </nav>
                  </div>
            </ng-container>
        </div>
        
      </div>
    </div>
  </div>