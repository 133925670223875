import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HelperService } from '@app/xServices/help.service';
import { StoreManageService } from '@app/xServices/StoreManageService.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(private router: Router, private storeManageService: StoreManageService, private helperService: HelperService) { }

  ngOnInit(): void {
    //let userDataStore = this.storeManageService.GetUserData();
    let userId = this.storeManageService.GetContactId(); 
    // If token not expire => My page
    if (!this.helperService.isEmptyOrSpaces(userId)) {
      this.router.navigate(["/mypage"]);
    }
  }

}
