import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HelperService } from '@app/xServices/help.service';
import { StoreManageService } from '@app/xServices/StoreManageService.service';

@Component({
  selector: 'app-welcome-to-my-page',
  templateUrl: './welcome-to-my-page.component.html',
  styleUrls: ['./welcome-to-my-page.component.scss']
})
export class WelcomeMyPageComponent implements OnInit {

  constructor(private router: Router, private storeManageService: StoreManageService, private helperService: HelperService) { }

  ngOnInit(): void {
    let userId = this.storeManageService.GetContactId(); 
    // If token not expire => My page
    if (!this.helperService.isEmptyOrSpaces(userId)) {
      this.router.navigate(["/mypage"]);
    }
  }

}
