<div class="row ">
  <div class="col-12 bg-primary-header mt-3 text-start ps-4">
    <p class="fs-4 pt-3 fw-normal">Mitt medlemskap</p>
  </div>
</div>
<div class="row pt-4 ps-3">
  <div class="col-12 col-md-8 mb-4" *ngIf="editModeMembership">
    <form [formGroup]="updateMembershipForm" class="notecard">

      <!--Edit Membership-->
      <ng-container *ngIf="editMembership">
        <p class="fs-4">Endre medlemskap</p>
        <label for="nInputNewMember" class="form-label small">Velg medlemskap til</label>
        <select class="form-select" formControlName="membershipType" id="nInputNewMember" (change)="onSelectEditMembershipChange($event.target)">
          <option *ngFor="let membership of MembershipList" [value]="membership.acan_vareid">
            {{membership.acan_name}}
          </option>
        </select>
      </ng-container>
      
      <!--Terminate Membership-->
      <ng-container *ngIf="endMembership">
        <p class="fs-4">Velg årsak for utmelding</p>
        <ng-container *ngFor="let reason of endMembershipReasons; let index = index">
          <label for="reason-{{reason.value}}">
            <input id="reason-{{reason.value}}" formControlName="reason-{{reason.value}}" (change)="onChangeReason($event, index)" type="checkbox"
              [value]="reason.value">
            {{reason.label}}
          </label>
          <br>
        </ng-container>

        <div class="col-12" *ngIf="btnReasonOther">
          <label for="nInputJobb" class="form-label small">Vennligst spesifiser</label>
          <span class="text-danger">*</span>
          <input type="text" class="form-control" formControlName="endMembershipReasonText" id="resonText"
            placeholder="" maxlength="100" [required]="this.btnReasonOther ? 'required' : ''">
        </div>
      </ng-container>


      <div class="alert alert-warning mt-3">
        Ditt nåværende medlemskap vil fortsette ut denne
        faktureringsperioden
      </div>
      <div class="mt-3">
        <button class=" btn btn-primary text-nowrap f-2 fw-bold btn-wrapper-info" [disabled]="!btnEnd"
         id="btnSaveMembership" (click)="onSaveDataMembership()" *ngIf="editModeMembership">
          Lagre 
        </button>
        <button class=" btn text-nowrap  f-2 fw-bold " (click)="onCancelDataMembership()"
          *ngIf="editModeMembership">Avbryt</button>
      </div>
    </form>
  </div>
</div>

<!-- Current Membership Container -->
<div class="row flex-row-reverse" *ngIf="!editModeMembership">
  <!-- TOOLS Button action-->
  <div class="col-md-3">
    <div class="title-tools" *ngIf="!editModeMembership && userMembershipUpcoming.length == 0">
      <!-- END MEMBER SHIP -->
      <div class="icon-flex" *ngIf="editOrCreate">
        <div class="icon-wrapper btn-wrapper-info" (click)="onActionMembershipAct()">
          <i class="bi bi-pencil-fill"></i>
        </div>
      </div>
      
      <!-- CHANGE/EDIT MEMBER SHIP -->
      <div class="icon-flex" *ngIf="isCurrentMembership && isEnableToTerminate">
        <div class="icon-wrapper btn-wrapper-delete" (click)="onEndMembershipAct();">
          <i class="bi bi-x"></i>
        </div>
      </div>
    </div>
  </div>
  
  <div class="col-md-9">
    <ng-container *ngIf="isCurrentMembership">
      <div class="row ps-3 flex-row-reverse">
          <!-- Main part -->
          <div class="title-container">
            <p class="fs-4">{{userMembershipCurrent.memberType}}</p>
          </div>
          
          <!-- MEMBERSHIP Current part2 -->
          <div class="row">
            <div class="col-12 pb-2 ">
              <p *ngIf="userMembershipCurrent.memberNumber">Medlemsnr: {{userMembershipCurrent.memberNumber }} </p>
              <p *ngIf="userMembershipCurrent.memberFrom">Fra: {{userMembershipCurrent.memberFrom | date:'dd.MM.yy'}}</p>
              <span *ngIf="userMembershipCurrent.memberTo">
                Til: {{userMembershipCurrent.memberTo | date:'dd.MM.yy'}}
              </span>          
            </div>
          </div>
        </div>
    </ng-container>
  </div>

</div>

<div class="row" *ngIf="userMembershipUpcoming?.length == 0">
  <div class="col-12">        
    <div class="icon-flex" >
      <div class="icon-wrapper btn-wrapper-info size2" *ngIf="!editOrCreate" (click)="onActionMembershipAct()">
        <i class="bi bi-plus"></i> 
      </div>
    </div>
  </div>  
</div>


<!-- MEMBERSHIP Upcoming-->
<div class="row" *ngIf="!editModeMembership">
  <div class="col-12">
    <ul class="membership-container">
      <ng-container *ngFor="let item of userMembershipUpcoming">
        <li>
          <div class="title-container">
            <div class="row">
              <div class="col-md-8">
                <p class="fs-4 ">{{item.memberType.toString()}} </p>
              </div>
              <div class="col-md-4 ">
                <div class="item-tools text-right">
                  <div class="icon-flex" *ngIf="item && item.memberTo == null">
                    <div class="icon-wrapper btn-wrapper-delete" (click)="onEndUpcommingMembership(item.id);">
                      <i class="bi bi-x"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <span>Starter: {{item.memberFrom | date:'dd.MM.yy'}}</span>
          <span *ngIf="item.memberTo">Til: {{item.memberTo | date:'dd.MM.yy'}}</span>
        </li>
      </ng-container>
    </ul>
  </div>
</div>

<!-- MEMBERSHIP HISTORY PART-->
<div class="row">
  <div class="col-12 pl-2">
    <p class="membership-history-text" 
      (click)="showHistoryMembership=!showHistoryMembership"
      *ngIf="userMembershipOld.length > 0">
      {{(showHistoryMembership)? "Skjul tidligere medlemsskap":"Vis tidligere medlemsskap"}}
      <span class="chevron" [ngClass]="{'active' : showHistoryMembership}"></span>
    </p>
  </div>
</div>
<ng-container *ngIf="showHistoryMembership == true" class="row">
  <div class="col-12 mt-1 text-center">  
    <p class="fs-4 fw- pt-3 title-sub-menu">Tidligere medlemskap</p>
  </div>
  <div class="row d-none d-md-flex mb-2 mt-3">
    <div class="col-4">
    </div>
    <div class="col-4">
      Fra:<br>
    </div>
    <div class="col-4">
      Til:<br>
    </div>
  </div>
  <ng-container *ngFor="let item of userMembershipOld">
    <div class="row mb-5 mb-md-2">
      <div class="col-12 col-md-4">
        <p class="fs-5 fw-normal">{{item.memberType.toString()}} </p>
      </div>
      <div class="col-4">
        <p class="fs-5 ">{{item.memberFrom | date:'dd.MM.yy'}}</p>
      </div>
      <div class="col-4">
        <p class="fs-5 ">{{item.memberTo | date:'dd.MM.yy'}}</p>
      </div>
    </div>
  </ng-container>
</ng-container>