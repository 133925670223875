/* Test ENV */

export const environment = {
  production: true
  , name: 'stage'
  , apiUrl: 'https://cartagenamedlemapi.azurewebsites.net/api/v1'
  , returnUrl:'https://medlem.cartagena.no/login/url_redirect'
  , crmUrl:'https://krifatest.api.crm4.dynamics.com/api/data/v9.2'

  , BankIdUrl: 'https://auth.current.bankid.no/auth/realms/current/'
  , client_id: "cartagena-current"
  , redirect_uri:"https://medlem.cartagena.no/login/url_redirect"
  , membershipCondition:"38893A70-A419-EE11-8F6D-000D3A229447"
  , personalDataCondition:"8B8C0C89-A419-EE11-8F6D-000D3A229447"
  , consentEmail: "E8EE4964-A419-EE11-8F6D-000D3A229447"
  , consentSMS: "23EBF75C-A419-EE11-8F6D-000D3A229447"
};