
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CrmService } from './crm.service';
import { Observable } from 'rxjs';
import { BaseListResponse, BaseModel } from '@app/xModels/BaseModel';
import { MemberGroupAdminStat, MemberGroupInfomation, MemberGroupMenu, MemberOnGroup } from '@app/xModels/MemberGroupModel';

@Injectable({
  providedIn: 'root'
})
export class WorkplaceService {

  constructor(private crmService: CrmService, private domSanitizer: DomSanitizer) { }

  public TerminateWorkplace(workplaceId: string) {
    var endPoint = "workplace/terminate/" + workplaceId;
    return this.crmService.patch<any>(endPoint, {});
  }

  public upsertWorkplace(contactId: string, workplaceId: string, name: string, startDate: string, endDate: string) {
    var record: any = {};
    record.ctg_contactId = contactId;
    record.ctg_name = name;
    record.ctg_datefrom = startDate;
    if (workplaceId != "") {
      record.ctg_workplaceId = workplaceId;
    }
    if (endDate) {
      record.ctg_dateto = endDate;
    }

    var endPoint = "workplace/upsert";
    let sendData = JSON.stringify(record);
    return this.crmService.post<any>(endPoint, sendData, false);
  }

  public memberGroupMenus(): Observable<BaseModel<Array<MemberGroupMenu>>> {
    var endPoint = "workplace/MemberGroupMenus";
    return this.crmService.get<any>(endPoint);
  }

  public memberGroupInfo(memberGroupId: string): Observable<BaseModel<MemberGroupInfomation>> {
    var endPoint = "workplace/MemberGroupInfo/" + memberGroupId;
    return this.crmService.get<any>(endPoint);
  }

  public memberGroupStat(memberGroupId: string): Observable<BaseModel<MemberGroupAdminStat>> {
    var endPoint = "MemberGroup/MemberGroupAdminStatistic/" + memberGroupId;
    return this.crmService.get<any>(endPoint);
  }

  public memberGroupUsers(memberGroupId: string
    , isOnlyPosted: boolean
    , statusOfMember: string | null
    , page : number
    , limit: number
    , sortColumn: string
    , sortDirection: string
    , filterText: string | null): Observable<BaseListResponse<MemberOnGroup>> {
    let param = {
      memberGroupId: memberGroupId,
      statusOfMember: statusOfMember,
      isOnlyPosted: isOnlyPosted,
      page: page,
      rowlimit: limit, 
      sortColumn: sortColumn,
      sortDirection: sortDirection,
      filterText: filterText
    };
    var endPoint = "workplace/GetMemberOnGroup";
    return this.crmService.post<BaseListResponse<MemberOnGroup>>(endPoint, param);
  }

  public SendEmailMemberGroup(contact: string, contacts: string[], accounts: string[], subject: string, body: string): Observable<BaseModel<boolean>> {
    let param = {
      owner: contact,
      emailSubject: subject,
      emailBody: body, 
      contactRecipients: contacts,
      accountRecipients: accounts,
    };
    var endPoint = "workplace/EmailMemberOnGroup";
    return this.crmService.post<BaseModel<boolean>>(endPoint, param);
  }
  
};

